import { Group, InputOutLabal } from '@/ui';
import { Alert, Box, Button, Snackbar, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import KeyIcon from '@mui/icons-material/Key';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { User } from '@/interfaces/user';
import { SaveCancel } from '@/components';
import { myFetch } from '@/utils';
import SETTINGS from '@/settings';
import { SNACKBAR_CONFIG } from '@/constants';
import { useDisclosure } from '@/hooks';
import { ModalPassword } from '@/components/modals/modal-password/ModalPassword';
import { logout } from '@/utils/logout';

const Lk = () => {
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [snackbarOpened, snackbarActions] = useDisclosure(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState<User | null>(null);
  const [formValues, setFormValues] = useState<Partial<User>>({});

  const handleChange = (field: string) => (value: string) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    const func = async () => {
      setLoading(true);
      try {
        const response = await myFetch(
          `${SETTINGS.SITE_URL}/api/v1/users/profile/me/`,
          {},
          navigate
        );
        if (!response.ok) throw new Error('Ошибка при получении данных о пользователе.');
        const user = await response.json();
        setUser(user);
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          setSnackbarMessage(error.message);
          snackbarActions.open();
          setIsSuccess(false);
        }
      } finally {
        setLoading(false);
      }
    };
    func();
  }, [navigate, snackbarActions, snackbarActions.open]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/users/profile/update_me/`,
        { method: 'PATCH', body: JSON.stringify(formValues) },
        navigate
      );
      if (!response.ok) throw new Error('Ошибка');
      setIsSuccess(true);
      setSnackbarMessage('Данные сохранились');
      snackbarActions.open();
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    } catch (error) {
      console.error(error);
      setSnackbarMessage('Произошла ошибка');
      snackbarActions.open();
      setIsSuccess(false);
    }
  };

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <div className="applications-page">
      <Snackbar
        open={snackbarOpened}
        autoHideDuration={SNACKBAR_CONFIG.autoHideDuration}
        onClose={snackbarActions.close}
        anchorOrigin={SNACKBAR_CONFIG.anchorOrigin}
      >
        <Alert
          onClose={snackbarActions.close}
          severity={isSuccess ? 'success' : 'warning'}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <ModalPassword buttonRef={buttonRef} />
      <div className="page-top d-flex items-center">
        <div className="page-top__left d-flex items-center">
          <span className="page-top__title">Личные данные</span>
        </div>
        <div className="page-top__right d-flex items-center">
          <div className="page-top__dispatcher d-flex items-center">
            <div className="page-top__dispatcher-icon circle-center">
              <span className="icon-settings-b"></span>
            </div>

            <span className="page-top__dispatcher-text d-flex items-center">
              Диспетчер · <span className="transition">{user?.full_name}</span>{' '}
              <span className="icon-angle-down transition"></span>
            </span>

            <div className="dispatcher-menu">
              <div className="dispatcher-menu__inner">
                <span className="dispatcher-menu__item transition" onClick={() => logout(navigate)}>
                  Выйти
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <form id="lkEdit" onSubmit={handleSubmit}>
          <Box sx={{ padding: '40px' }}>
            <Stack gap={1.5}>
              <Group gap={15}>
                <InputOutLabal
                  label="Логин"
                  defaultValue={user?.username}
                  w={300}
                  bg=""
                  onChange={(event) => handleChange('username')(event.target.value)}
                />
                <InputOutLabal
                  label="Имя"
                  defaultValue={user?.first_name}
                  w={300}
                  bg=""
                  onChange={(event) => handleChange('first_name')(event.target.value)}
                />
                <InputOutLabal
                  label="Фамилия"
                  defaultValue={user?.last_name}
                  w={300}
                  bg=""
                  onChange={(event) => handleChange('last_name')(event.target.value)}
                />
                <InputOutLabal label="Роль" defaultValue={user?.kind} w={300} disabled />
              </Group>
              <Group gap={15}>
                <InputOutLabal
                  label="Почта"
                  defaultValue={user?.email}
                  w={300}
                  bg=""
                  onChange={(event) => handleChange('email')(event.target.value)}
                />
              </Group>
            </Stack>
            <Box sx={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
              <KeyIcon />
              <Button ref={buttonRef}>Изменить пароль</Button>
            </Box>
            <SaveCancel formId="lkEdit" />
          </Box>
        </form>
      </div>
    </div>
  );
};

export default Lk;
