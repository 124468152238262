import { useCallback, useMemo, useState } from 'react';
import { ReqForm } from '../../interfaces/req';
import { myFetch } from '../../utils';
import SETTINGS from '../../settings';
import { useNavigate } from 'react-router-dom';
import { ERROR_MESSAGES, VALIDATION_ADD_FIELDS } from '../../constants/constants';

const initialFormData: Partial<ReqForm> = {
  kind: 'Поставить - Забрать',
  client: null,
  company_object: null,
  source: null,
  container: null,
  location: null,
  req_date: null,
  total_sum: 0,
  address_coordinates: null,
  address_location: null,
  dispatcher1: null,
};

const warning_value: { [key in keyof Partial<ReqForm>]: string } = {
  client: 'Клиента',
  address_location: 'Адрес объекта',
  source: 'Источник заявки',
};

export const useReqAddForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<Partial<ReqForm>>(initialFormData);
  const [snackbarMessage, setSnackBarMessage] = useState(ERROR_MESSAGES.DEFAULT);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>, onOpenModal: () => void) => {
      event.preventDefault();

      const requiredFields: (keyof ReqForm)[] = VALIDATION_ADD_FIELDS;
      const missingFields = requiredFields.filter((field) => {
        if (field === 'source' && formData.kind === 'База - Полигон') {
          return false;
        } else {
          return !formData[field];
        }
      });

      if (missingFields.length > 0) {
        const warnings = missingFields.map((field) => warning_value[field]).join(', ');
        setSnackBarMessage(`Пожалуйста, заполните данные: ${warnings}`);
        onOpenModal();
        return;
      }
      try {
        const res = await myFetch(
          `${SETTINGS.SITE_URL}/api/v1/reqs/`,
          {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          },
          navigate
        );
        if (res.ok) {
          const data = await res.json();
          setIsSuccess(true);
          onOpenModal();

          setSnackBarMessage(
            `“Заявка №${data.id} успешно сохранена, Статус заявки: ${data.status}`
          );
          setTimeout(() => {
            navigate('/reqs');
          }, 2000);
        } else {
          onOpenModal();
          const jsonData = await res.json();
          const errorMessage = VALIDATION_ADD_FIELDS.map((msg) => jsonData[msg]).find(Boolean);
          if (errorMessage) {
            setSnackBarMessage(errorMessage);
          } else {
            setSnackBarMessage('Произошла ошибка при сохранении заявки.');
          }
        }
      } catch (error) {
        console.error('Ошибка при отправке данных:', error);
        setSnackBarMessage('Произошла ошибка при отправке данных.');
        onOpenModal();
      }
    },
    [formData, navigate]
  );

  return useMemo(
    () => ({ formData, setFormData, snackbarMessage, handleSubmit, isSuccess }),
    [formData, snackbarMessage, handleSubmit, isSuccess]
  );
};
