import { Routes, Route, BrowserRouter } from 'react-router-dom';
import MapWithReq from './pages/MapWithReqs';
import ReqList from './pages/reqs/ReqList';
import ReqEdit from './pages/reqs/ReqEdit';
import VehicleList from './pages/vehicles/VehicleList';
import Layout from './components/Layout';
import DriverList from './pages/drivers/DriverList';
import CompanyList from './pages/companies/CompanyList';
import ContainerList from './pages/containers/ContainerList';
import ReqAdd from './pages/reqs/ReqAdd';
import VehicleEdit from './pages/vehicles/VehicleEdit';
import VehicleAdd from './pages/vehicles/VehicleAdd';
import Login from './components/Login';
import DriverInstructionsPage from './pages/drivers/DriverInstructionPage';
import './App.scss';
import ContainerAdd from './pages/containers/ContainerAdd';
import ContainerEdit from './pages/containers/ContainerEdit';
import CompanyAdd from './pages/companies/CompanyAdd';
import CompanyEdit from './pages/companies/CompanyEdit';
import DriverAdd from './pages/drivers/DriverAdd';
import DriverEdit from './pages/drivers/DriverEdit';
import StatPage from './pages/stats/StatPage';
import VehicleSendToRepair from './pages/vehicles/VehicleSendToRepair';
import LogUserActionList from './pages/logs/LogUserActionList';
import { registerServices } from './utils/services';
import Lk from './pages/lk/lk';

function App() {
  registerServices();
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<MapWithReq />} />
            <Route path="/reqs" element={<ReqList />} />
            <Route path="/reqs/:reqId/edit" element={<ReqEdit />} />
            <Route path="/reqs/add" element={<ReqAdd />} />
            <Route path="/drivers" element={<DriverList />} />
            <Route path="/drivers/add" element={<DriverAdd />} />
            <Route path="/drivers/:driverId/edit" element={<DriverEdit />} />
            <Route path="/drivers/instructions/:pageSecret" element={<DriverInstructionsPage />} />
            <Route path="/companies" element={<CompanyList />} />
            <Route path="/companies/add" element={<CompanyAdd />} />
            <Route path="/companies/:companyId/edit" element={<CompanyEdit />} />
            <Route path="/vehicles" element={<VehicleList />} />
            <Route path="/vehicles/:vehicleId/edit" element={<VehicleEdit />} />
            <Route path="/vehicles/:vehicleId/send-to-repair" element={<VehicleSendToRepair />} />
            <Route path="/vehicles/add" element={<VehicleAdd />} />
            <Route path="/containers" element={<ContainerList />} />
            <Route path="/containers/add" element={<ContainerAdd />} />
            <Route path="/containers/:cId/edit" element={<ContainerEdit />} />
            <Route path="/login" element={<Login />} />
            <Route path="/stats" element={<StatPage />} />
            <Route path="/logs" element={<LogUserActionList />} />
            <Route path="/lk" element={<Lk />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
