import { useCallback, useMemo, useState } from 'react';

export type ModalKeys =
  | 'date'
  | 'date_confirm'
  | 'refuse'
  | 'refuse_confirm'
  | 'idleFlight'
  | 'idleFlight_confirm'
  | 'overloading'
  | 'overloading_confirm'
  | 'createReq_confirm'
  | 'driverfire_confirm';

type ModalState = {
  [key in ModalKeys]?: boolean;
};
const defaultValue: ModalState = {
  date: false,
  date_confirm: false,
  refuse: false,
  refuse_confirm: false,
  createReq_confirm: false,
  driverfire_confirm: false,
};

export const useConfirmModals = () => {
  const [modalStates, setModalStates] = useState<ModalState>(defaultValue);

  const toggleModal = useCallback((modalName: ModalKeys) => {
    setModalStates((prev) => ({ ...prev, [modalName]: !prev[modalName] }));
  }, []);
  return useMemo(() => ({ modalStates, toggleModal }), [modalStates, toggleModal]);
};
