import { InputOutLabal } from '@/ui';
import { Stack } from '@mui/material';
import { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';

type ModalCostValueProps = PropsWithChildren & {
  label: string;
  onValue: Dispatch<SetStateAction<string>>;
};
export const ModalCostValue: FC<ModalCostValueProps> = ({ children, label, onValue }) => {
  return (
    <Stack gap={3}>
      <InputOutLabal
        label={label}
        bg=""
        placeholder="Введите сумму"
        onChange={(e) => onValue(e.target.value)}
      />
      {children}
    </Stack>
  );
};
