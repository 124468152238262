import { ModalMui } from '@/components/Modal';
import { FC, RefObject, useCallback, useEffect, useState } from 'react';
import { Buttons } from './Buttons';
import { ModalCurrentPassword } from './ModalCurrentPassword';
import { ModalChangePassword } from './ModalChangePassword';
import { ModalSuccess } from './ModalSuccess';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@/hooks';
import { Stack } from '@mui/material';
import { myFetch } from '@/utils';
import SETTINGS from '@/settings';

export interface PasswordChange {
  old_password: string;
  new_password: string;
  new_password_confirm: string;
}
export type Error = {
  [key in keyof PasswordChange]: string[];
};

type ModalPassword = {
  buttonRef: RefObject<HTMLButtonElement>;
};
export const ModalPassword: FC<ModalPassword> = ({ buttonRef }) => {
  const [stage, setStage] = useState(1);
  const navigate = useNavigate();
  const [modalOpened, modalActions] = useDisclosure(false);

  const [error, setError] = useState<Error | null>(null);

  const [formValues, setFormValues] = useState<PasswordChange>({
    old_password: '',
    new_password: '',
    new_password_confirm: '',
  });

  useEffect(() => {
    const button = buttonRef.current;
    const openModal = () => modalActions.open();
    if (button) {
      button.addEventListener('click', openModal);
    }

    return () => {
      if (button) {
        button.removeEventListener('click', openModal);
      }
    };
  }, [buttonRef, modalActions]);

  useEffect(() => {
    if (!modalOpened)
      setFormValues({
        old_password: '',
        new_password: '',
        new_password_confirm: '',
      });
  }, [modalOpened]);

  const handleContinue = () => {
    setStage((prev) => prev + 1);
  };
  const handleCancel = () => {
    setStage((prev) => prev - 1);
  };

  const handleChange = useCallback(
    (field: string) => (value: string) => {
      setFormValues((prev) => ({ ...prev, [field]: value }));
    },
    []
  );

  const handleSubmit = useCallback(async () => {
    try {
      const response = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/users/profile/change_password/`,
        { method: 'POST', body: JSON.stringify(formValues) },
        navigate
      );
      if (!response.ok) {
        const error = await response.json();
        setError(error);
        if (error.old_password) {
          handleCancel();
        }
        throw new Error('Ошибка в изменении пароля');
      }
      handleContinue();
    } catch (error) {
      console.error(error);
    }
  }, [formValues, navigate]);

  const getButtons = useCallback(
    (continueAction = handleContinue, cancelAction = handleCancel) => (
      <Buttons onContinue={continueAction} onCancel={cancelAction} stage={stage} />
    ),
    [stage]
  );

  const getModalContent = useCallback(() => {
    switch (stage) {
      case 1:
        return {
          content: (
            <ModalCurrentPassword
              onValue={handleChange}
              enteredValue={formValues.old_password}
              error={error}
            >
              {getButtons(handleContinue, () => modalActions.close())}
            </ModalCurrentPassword>
          ),
          title: 'Смена пароля',
        };

      case 2:
        return {
          content: (
            <ModalChangePassword
              onValue={handleChange}
              enteredValue={{
                new: formValues.new_password,
                new_confirm: formValues.new_password_confirm,
              }}
              error={error}
            >
              {getButtons(handleSubmit)}
            </ModalChangePassword>
          ),
          title: 'Смена пароля',
        };

      case 3:
        return {
          content: <ModalSuccess>{getButtons(() => navigate(-1))}</ModalSuccess>,
          title: null,
        };

      default:
        return {
          content: null,
          title: null,
        };
    }
  }, [
    error,
    formValues.new_password,
    formValues.new_password_confirm,
    formValues.old_password,
    getButtons,
    handleChange,
    handleSubmit,
    modalActions,
    navigate,
    stage,
  ]);

  const { title, content } = getModalContent();
  return (
    <ModalMui opened={modalOpened} onClose={modalActions.close} title={title ?? ''} w={420}>
      <Stack sx={{ justifyContent: 'center', alignItems: 'center', padding: '20px' }} gap={2}>
        {content}
      </Stack>
    </ModalMui>
  );
};
