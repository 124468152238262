import { useEffect, useRef, useState } from 'react';
import SETTINGS from '../../settings';
import MyForm from '../../components/MyForm';
import { myFetch } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import companyAddFields from '../../form-fields/company-add';
import { Badge, TextField } from '@mui/material';
import { ViewReqs } from '../../components/company/view-reqs/ViewReqs';
import { Company } from '../../interfaces/company';
import { Req } from '../../interfaces/req';
import { AddressSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';

const CompanyAdditionalFields = ({
  companyId,
  company,
  setCompany,
  addCp,
  setAddCp,
  cp,
  setCp,
  addBa,
  ba,
  setBa,
  setAddBa,
  co,
  setCo,
  addCo,
  setAddCo,
  errors,
  setCompanyEdit,
}: {
  companyId: number;
  company: any;
  setCompany: Function;
  addCp: boolean;
  cp: any;
  setCp: Function;
  setAddCp: Function;
  addCo: boolean;
  co: any;
  setCo: Function;
  setAddCo: Function;
  addBa: boolean;
  ba: any;
  setBa: Function;
  setAddBa: Function;
  errors: any;
  setErrors: Function;
  setCompanyEdit: Function;
}) => {
  return (
    <>
      <div className="form d-flex flex-wrap">
        <div className="form__title">Расчетные счета</div>
        {company.bank_accounts.map((ba1: any, index: number) => {
          return (
            <div
              key={index}
              className="form__row"
              style={{
                border: ba1.to_delete ? (ba1.id ? '1px solid red' : '1px solid orange') : '',
              }}
            >
              <div className="form__row-inner d-flex">
                <div className="form__row-field">
                  <TextField
                    value={ba1.bik}
                    label="БИК"
                    error={errors?.bank_accounts?.[index]?.bik?.[0] || null}
                    onChange={(e) => {
                      ba1.bik = e.target.value;
                      setCompany({
                        ...company,
                        bank_accounts: JSON.parse(JSON.stringify(company.bank_accounts)),
                      });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div className="form__row-field">
                  <TextField
                    value={ba1.account}
                    label="Счет"
                    error={errors?.bank_accounts?.[index]?.account?.[0] || null}
                    onChange={(e) => {
                      ba1.account = e.target.value;
                      setCompany({
                        ...company,
                        bank_accounts: JSON.parse(JSON.stringify(company.bank_accounts)),
                      });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div className="form__row-field">
                  <TextField
                    value={ba1.bank_name}
                    label="Наименование банка"
                    error={errors?.bank_accounts?.[index]?.bank_name?.[0] || null}
                    onChange={(e) => {
                      ba1.bank_name = e.target.value;
                      setCompany({
                        ...company,
                        bank_accounts: JSON.parse(JSON.stringify(company.bank_accounts)),
                      });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div className="form__row-field">
                  <TextField
                    value={ba1.corr_account}
                    label="Корр. счет"
                    error={errors?.bank_accounts?.[index]?.corr_account?.[0] || null}
                    onChange={(e) => {
                      ba1.corr_account = e.target.value;
                      setCompany({
                        ...company,
                        bank_accounts: JSON.parse(JSON.stringify(company.bank_accounts)),
                      });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div
                  className="form__row-action d-flex block-center"
                  onClick={() => {
                    ba1.to_delete = !ba1.to_delete;
                    setCompany({
                      ...company,
                      bank_accounts: JSON.parse(JSON.stringify(company.bank_accounts)),
                    });
                  }}
                >
                  <span
                    className={`icon-${ba1.to_delete ? 'check-circle' : 'delete'} transition`}
                  ></span>
                  {/*{cp1.to_delete ? 'Add' : 'Del'}*/}
                </div>
              </div>
            </div>
          );
        })}
        {addBa && (
          <>
            <div className="form__row">
              <div className="form__row-inner d-flex">
                <div className="form__row-field">
                  <TextField
                    value={ba.bik}
                    label="БИК"
                    onChange={(e) => {
                      ba.bik = e.target.value;
                      setBa({ ...ba });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div className="form__row-field">
                  <TextField
                    value={ba.account}
                    label="Счет"
                    onChange={(e) => {
                      ba.account = e.target.value;
                      setBa({ ...ba });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div className="form__row-field">
                  <TextField
                    value={ba.bank_name}
                    label="Телефон"
                    onChange={(e) => {
                      ba.bank_name = e.target.value;
                      setBa({ ...ba });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div className="form__row-field">
                  <TextField
                    value={ba.corr_account}
                    label="Почта"
                    onChange={(e) => {
                      ba.corr_account = e.target.value;
                      setBa({ ...ba });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div
                  className="form__row-action d-flex block-center"
                  onClick={() => {
                    ba.company = companyId;
                    company.bank_accounts.push(ba);
                    setCompany({
                      ...company,
                      bank_accounts: JSON.parse(JSON.stringify(company.bank_accounts)),
                    });
                    setBa({
                      bik: '',
                      account: '',
                      bank_name: '',
                      corr_account: '',
                    });
                  }}
                >
                  <span className="icon-check-circle transition"></span>
                </div>
              </div>
            </div>
          </>
        )}
        {!addBa && (
          <button
            className="form__add d-flex items-center transition"
            type="button"
            onClick={() => {
              setAddBa(true);
            }}
          >
            Добавить расчетный счет
          </button>
        )}
      </div>

      <div className="form d-flex flex-wrap">
        <div className="form__title">Контактные лица</div>
        {company.contact_persons.map((cp1: any, index: number) => {
          return (
            <div
              key={index}
              className="form__row"
              style={{
                border: cp1.to_delete ? (cp1.id ? '1px solid red' : '1px solid orange') : '',
              }}
            >
              <div className="form__row-inner d-flex">
                <div className="form__row-field">
                  <TextField
                    value={cp1.first_name}
                    label="Имя"
                    error={errors?.contact_persons?.[index]?.first_name?.[0] || null}
                    onChange={(e) => {
                      cp1.first_name = e.target.value;
                      setCompany({
                        ...company,
                        contact_persons: JSON.parse(JSON.stringify(company.contact_persons)),
                      });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div className="form__row-field">
                  <TextField
                    value={cp1.last_name}
                    label="Фамилия"
                    error={errors?.contact_persons?.[index]?.last_name?.[0] || null}
                    onChange={(e) => {
                      cp1.last_name = e.target.value;
                      setCompany({
                        ...company,
                        contact_persons: JSON.parse(JSON.stringify(company.contact_persons)),
                      });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div className="form__row-field">
                  <TextField
                    value={cp1.phone}
                    label="Телефон"
                    error={errors?.contact_persons?.[index]?.phone?.[0] || null}
                    onChange={(e) => {
                      cp1.phone = e.target.value;
                      setCompany({
                        ...company,
                        contact_persons: JSON.parse(JSON.stringify(company.contact_persons)),
                      });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div className="form__row-field">
                  <TextField
                    value={cp1.email}
                    label="Почта"
                    error={errors?.contact_persons?.[index]?.email?.[0] || null}
                    onChange={(e) => {
                      cp1.email = e.target.value;
                      setCompany({
                        ...company,
                        contact_persons: JSON.parse(JSON.stringify(company.contact_persons)),
                      });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div
                  className="form__row-action d-flex block-center"
                  onClick={() => {
                    cp1.to_delete = !cp1.to_delete;
                    setCompany({
                      ...company,
                      contact_persons: JSON.parse(JSON.stringify(company.contact_persons)),
                    });
                  }}
                >
                  <span
                    className={`icon-${cp1.to_delete ? 'check-circle' : 'delete'} transition`}
                  ></span>
                  {/*{cp1.to_delete ? 'Add' : 'Del'}*/}
                </div>
              </div>
            </div>
          );
        })}
        {addCp && (
          <>
            <div className="form__row">
              <div className="form__row-inner d-flex">
                <div className="form__row-field">
                  <TextField
                    value={cp.first_name}
                    label="Имя"
                    onChange={(e) => {
                      cp.first_name = e.target.value;
                      setCp({ ...cp });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div className="form__row-field">
                  <TextField
                    value={cp.last_name}
                    label="Фамилия"
                    onChange={(e) => {
                      cp.last_name = e.target.value;
                      setCp({ ...cp });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div className="form__row-field">
                  <TextField
                    value={cp.phone}
                    label="Телефон"
                    onChange={(e) => {
                      cp.phone = e.target.value;
                      setCp({ ...cp });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div className="form__row-field">
                  <TextField
                    value={cp.email}
                    label="Почта"
                    onChange={(e) => {
                      cp.email = e.target.value;
                      setCp({ ...cp });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div
                  className="form__row-action d-flex block-center"
                  onClick={() => {
                    cp.company = companyId;
                    company.contact_persons.push(cp);
                    setCompany({
                      ...company,
                      contact_persons: JSON.parse(JSON.stringify(company.contact_persons)),
                    });
                    setCp({
                      email: '',
                      phone: '',
                      first_name: '',
                      last_name: '',
                    });
                  }}
                >
                  <span className="icon-check-circle transition"></span>
                </div>
              </div>
            </div>
          </>
        )}
        {!addCp && (
          <button
            className="form__add d-flex items-center transition"
            type="button"
            onClick={() => {
              setAddCp(true);
            }}
          >
            Добавить контактное лицо
          </button>
        )}
      </div>

      <div className="form d-flex flex-wrap">
        <div className="form__title">Объекты компании</div>
        {company.company_objects.map((co1: any, index: number) => {
          return (
            <div
              key={index}
              className="form__row"
              style={{
                border: co1.to_delete ? (co1.id ? '1px solid red' : '1px solid orange') : '',
              }}
            >
              <div className="form__row-inner d-flex">
                <div className="form__row-field">
                  <TextField
                    value={co1.point_id}
                    label="ID точки"
                    error={errors?.company_objects?.[index]?.point_id?.[0] || null}
                    onChange={(e) => {
                      co1.bik = e.target.value;
                      setCompany({
                        ...company,
                        company_objects: JSON.parse(JSON.stringify(company.company_objects)),
                      });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div className="form__row-field">
                  <TextField
                    value={co1.address}
                    label="Адрес"
                    error={errors?.company_objects?.[index]?.address?.[0] || null}
                    onChange={(e) => {
                      co1.account = e.target.value;
                      setCompany({
                        ...company,
                        company_objects: JSON.parse(JSON.stringify(company.company_objects)),
                      });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div className="form__row-field">
                  <TextField
                    value={co1?.contact_persons?.[0]?.first_name}
                    label="Лицо"
                    error={errors?.company_objects?.[index]?.contact_persons?.[0] || null}
                    onChange={(e) => {
                      co1.bank_name = e.target.value;
                      setCompany({
                        ...company,
                        company_objects: JSON.parse(JSON.stringify(company.company_objects)),
                      });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div className="form__row-field">
                  <TextField
                    value={co1?.contact_persons?.[0]?.phone}
                    label="Телефон"
                    error={errors?.company_objects?.[index]?.phone?.[0] || null}
                    onChange={(e) => {
                      co1.corr_account = e.target.value;
                      setCompany({
                        ...company,
                        company_objects: JSON.parse(JSON.stringify(company.company_objects)),
                      });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div
                  className="form__row-action d-flex block-center"
                  onClick={() => {
                    co1.to_delete = !co1.to_delete;
                    setCompany({
                      ...company,
                      company_objects: JSON.parse(JSON.stringify(company.company_objects)),
                    });
                  }}
                >
                  <span
                    className={`icon-${co1.to_delete ? 'check-circle' : 'delete'} transition`}
                  ></span>
                  {/*{cp1.to_delete ? 'Add' : 'Del'}*/}
                </div>
              </div>
            </div>
          );
        })}
        {addCo && (
          <>
            <div className="form__row">
              <div className="form__row-inner d-flex">
                <div className="form__row-field">
                  <TextField
                    value={co.id}
                    label="ID точки"
                    onChange={(e) => {
                      co.point_id = e.target.value;
                      setCo({ ...co });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div className="form__row-field">
                  {/* <TextField
                  value={co.address}
                  label="Адрес"
                  onChange={(e) => {
                    co.address = e.target.value;
                    setBa({ ...co });
                  }}
                  className="form__row-input"
                /> */}
                  <div className="address_suggestions">
                    <AddressSuggestions
                      token="5558df51b0171fb2e43f15732601f4aff66e44a8"
                      inputProps={{ placeholder: 'Адрес' }}
                      onChange={(event) => {
                        const geo_lat = event?.data.geo_lat;
                        const geo_lon = event?.data.geo_lon;
                        co.address = event?.value;
                        co.coordinates = `${geo_lat}, ${geo_lon}`;
                      }}
                    />
                  </div>
                </div>
                <div className="form__row-field">
                  <TextField
                    value={co.contact_person}
                    label="Лицо"
                    onChange={(e) => {
                      co.contact_persons[0].first_name = e.target.value;
                      co.contact_persons[0].last_name = 'Test';
                      co.contact_persons[0].second_name = 'Test';
                      co.contact_persons[0].email = 'test@testing.ru';
                      // setBa({ ...co });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div className="form__row-field">
                  <TextField
                    value={co.phone}
                    label="Телефон"
                    onChange={(e) => {
                      co.contact_persons[0].phone = e.target.value;
                      // setBa({ ...co });
                    }}
                    className="form__row-input"
                  />
                </div>
                <div
                  className="form__row-action d-flex block-center"
                  onClick={() => {
                    if (!co.address) return;
                    // co.company = companyId;
                    company.company_objects.push(co);

                    setCompanyEdit({
                      ...company,
                      company_objects: JSON.parse(JSON.stringify([co])),
                    });
                    // setCo({
                    //   point_id: 0,
                    //   address: '',
                    //   contact_persons: [
                    //     {
                    //       first_name: '',
                    //       last_name: '',
                    //       second_name: '',
                    //       phone: '',
                    //       email: '',
                    //     },
                    //   ],
                    //   coordinates: '',
                    // });
                    setAddCo(true);
                  }}
                >
                  <span className="icon-check-circle transition"></span>
                </div>
              </div>
            </div>
          </>
        )}
        {!addCo && (
          <button
            className="form__add d-flex items-center transition"
            type="button"
            onClick={() => {
              setAddCo(true);
            }}
          >
            Добавить объект компании
          </button>
        )}
      </div>
    </>
  );
};

const baseCp: any = {
  email: '',
  phone: '',
  first_name: '',
  last_name: '',
};
const baseBa: any = {
  bik: '',
  account: '',
  corr_account: '',
  bank_name: '',
};

const baseCo: any = {
  point_id: 0,
  address: '',
  contact_persons: [
    {
      first_name: '',
      last_name: '',
      second_name: '',
      phone: '',
      email: '',
    },
  ],
  coordinates: '',
};
const baseErrors: any = {};

type ReqsState = {
  [key in 'actived' | 'closed']: Req[] | null;
};

export default function CompanyEdit() {
  // const reqId = props.match.params.id
  const navigate = useNavigate();
  const { companyId } = useParams();

  const [company, setCompany] = useState<Company | null>(null);
  const [cp, setCp] = useState(baseCp);
  const [addCp, setAddCp] = useState(false);
  const [ba, setBa] = useState(baseBa);
  const [addBa, setAddBa] = useState(false);
  const [co, setCo] = useState(baseCo);
  const [addCo, setAddCo] = useState(false);
  const [errors, setErrors] = useState(baseErrors);
  const [activeTab, setActiveTab] = useState<'actived' | 'closed'>('actived');
  const [reqs, setReqs] = useState<ReqsState>({ closed: null, actived: null });
  const fetchedDataRef = useRef({
    actived: false,
    closed: false,
  });
  const [companyEdit, setCompanyEdit] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/companies/${companyId}/`,
        {},
        navigate
      );
      const result = await res.json();
      setCompany(result);
      setCompanyEdit(result);
    };
    fetchData();
  }, [companyId, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      if (!fetchedDataRef.current[activeTab]) {
        const res = await myFetch(
          `${SETTINGS.SITE_URL}/api/v1/reqs?client_id=${companyId}&active=${
            activeTab === 'actived'
          }`,
          {},
          navigate
        );
        const jsonData = await res.json();
        setReqs((prev) => ({ ...prev, [activeTab]: jsonData.results }));
        fetchedDataRef.current[activeTab] = true;
      }
    };
    fetchData();
  }, [navigate, activeTab, companyId]);

  if (!company || (!reqs.actived && !reqs.closed)) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="company-page d-flex flex-wrap items-start">
        <div className="page-top page-top--black d-flex items-center">
          <div className="page-top__left d-flex items-center">
            <div className="page-top__back circle-center" onClick={() => navigate(-1)}>
              <span className="icon-angle-left"></span>
            </div>
            <span className="page-top__title">{`Компания ID ${company.id} ИНН ${company.inn}`}</span>
            <div className="page-top__status client d-flex items-center">Клиент</div>
          </div>
          <div className="page-top__right d-flex items-center">
            <div className="page-top__block d-flex items-center">
              <div className="page-top__block-icon circle-center">
                <span className="icon-profile"></span>
              </div>
              Директор<b>· {company.director}</b>
            </div>

            <div className="page-top__block d-flex items-center">
              <div className="page-top__block-icon circle-center">
                <span className="icon-phone"></span>
              </div>
              Телефон <b>· +7{company.phone}</b>
            </div>

            <div className="page-top__block d-flex items-center">
              <div className="page-top__block-icon circle-center">
                <span className="icon-sms"></span>
              </div>
              Почта<b>· {company.email}</b>
            </div>
          </div>
        </div>

        <div className="company-page__content">
          <MyForm
            fields={companyAddFields}
            method="PUT"
            returnUrl={`/companies/`}
            formUrl={`${SETTINGS.SITE_URL}/api/v1/companies/${companyId}/`}
            state={companyEdit}
            setState={setCompany}
            errors={errors}
            setErrors={setErrors}
            additionalFields={
              <CompanyAdditionalFields
                company={company}
                setCompany={setCompany}
                addCp={addCp}
                cp={cp}
                setCp={setCp}
                companyId={parseInt(companyId!)}
                setAddCp={setAddCp}
                ba={ba}
                setBa={setBa}
                addBa={addBa}
                setAddBa={setAddBa}
                errors={errors}
                setErrors={setErrors}
                co={co}
                setCo={setCo}
                addCo={addCo}
                setAddCo={setAddCo}
                setCompanyEdit={setCompanyEdit}
              />
            }
          />
        </div>

        <div className="company-page__aside">
          <div className="company-page__details">
            <div className="details-calendar d-flex items-center">
              <div className="details-calendar__icon circle-center">
                <span className="icon-calendar"></span>
              </div>
              {/*<>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => {
                                    setDateKind('by_date')
                                    setStartDate(date!)
                                }}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd.MM.YYYY"
                                shouldCloseOnSelect
                                // showYearPicker
                            />
                            &nbsp;-&nbsp;
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => {
                                    setEndDate(date!)
                                    setDateKind('by_date')
                                }}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd.MM.YYYY"
                                shouldCloseOnSelect
                                // showYearPicker
                            />
                        </>*/}
            </div>

            <div className="details-list d-flex flex-wrap justify-between">
              <div className="details-list__item d-flex flex-column justify-center">
                <span className="details-list__item-title">Всего заявок</span>
                <span className="details-list__item-value">{company.count_reqs}</span>
                <span className="details-list__item-icon icon-routing"></span>
              </div>

              <div className="details-list__item d-flex flex-column justify-center">
                <span className="details-list__item-title">Заявок в работе</span>
                <span className="details-list__item-value">{company.count_reqs_in_work}</span>
                <span className="details-list__item-icon icon-refresh-circle"></span>
              </div>

              <div className="details-list__item d-flex flex-column justify-center">
                <span className="details-list__item-title">Объектов</span>
                <span className="details-list__item-value">{company.company_objects.length}</span>
                <span className="details-list__item-icon icon-building"></span>
              </div>

              <div className="details-list__item d-flex flex-column justify-center">
                <Badge badgeContent={'в работе'} color="warning">
                  <span className="details-list__item-title">Наших контейнеров</span>
                </Badge>
                <span className="details-list__item-value">-</span>
                <span className="details-list__item-icon icon-box"></span>
              </div>
            </div>

            <div className="details-finances d-flex justify-between">
              <div className="details-finances__item d-flex flex-column justify-center">
                <Badge badgeContent={'в работе'} color="warning"></Badge>
                <span className="details-finances__item-title">Сумма вывоза</span>
                <span className="details-finances__item-value">-</span>
              </div>
              <div className="details-finances__item d-flex flex-column justify-center">
                <span className="details-finances__item-title">Задолженность</span>
                <span className="details-finances__item-value">
                  {company.overall_debt?.toLocaleString()} ₽
                </span>
              </div>
            </div>
          </div>

          <div className="company-page__history">
            <div className="company-page__history-tabs d-flex">
              <div
                className={`company-page__history-tab ${
                  activeTab === 'actived' && 'is-active'
                } d-flex block-center transition`}
                onClick={() => setActiveTab('actived')}
              >
                Активные заявки
              </div>
              <div
                className={`company-page__history-tab d-flex block-center transition ${
                  activeTab === 'closed' && 'is-active'
                }`}
                onClick={() => setActiveTab('closed')}
              >
                Закрытые заявки
              </div>
            </div>

            <div className="company-page__history-content d-flex flex-wrap">
              <div className="tab-content d-flex flex-wrap">
                <ViewReqs reqs={reqs[activeTab]} />

                <a
                  href=""
                  className="company-page__history-download d-flex items-center transition"
                  download
                >
                  <span className="icon-document-text transition"></span>
                  Скачать список заказов
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
