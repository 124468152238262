import { FC } from 'react';
import { ModalConfirm } from './ModalConfirm';
import { FormConfirmModals } from '../../../interfaces/req';

export const EditConfirmModals: FC<FormConfirmModals> = ({ configs, states, handlers }) => {
  return (
    <>
      {handlers.date && (
        <ModalConfirm
          config={{
            ...configs.date!,
            opened: states.date ?? false,
            onConfirm: handlers.date.onConfirm,
            close: handlers.date.onClose,
          }}
        />
      )}
      {handlers.refuse && (
        <ModalConfirm
          config={{
            ...configs.refuse!,
            opened: states.refuse ?? false,
            onConfirm: handlers.refuse.onConfirm,
            close: handlers.refuse.onClose,
          }}
        />
      )}
      {handlers.refuse_confirm && (
        <ModalConfirm
          config={{
            ...configs.refuse_confirm!,
            opened: states.refuse_confirm ?? false,
            onConfirm: handlers.refuse_confirm.onConfirm,
            close: handlers.refuse_confirm.onClose,
          }}
        />
      )}
      {handlers.createReq_confirm && (
        <ModalConfirm
          config={{
            ...configs.createReq_confirm!,
            opened: states.createReq_confirm ?? false,
            onConfirm: handlers.createReq_confirm.onConfirm,
            close: handlers.createReq_confirm.onClose,
          }}
        />
      )}
      {handlers.driverfire_confirm && (
        <ModalConfirm
          config={{
            ...configs.driverfire_confirm!,
            opened: states.driverfire_confirm ?? false,
            onConfirm: handlers.driverfire_confirm.onConfirm,
            close: handlers.driverfire_confirm.onClose,
          }}
        />
      )}
    </>
  );
};
