import { useCallback, useMemo, useState } from 'react';

export const useDisclosure = (
  initial: boolean = false
): [boolean, { open: () => void; close: () => void }] => {
  const [opened, setOpened] = useState<boolean>(initial);

  const open = useCallback(() => {
    setOpened(true);
  }, []);

  const close = useCallback(() => {
    setOpened(false);
  }, []);

  return useMemo(() => [opened, { open, close }], [close, open, opened]);
};
