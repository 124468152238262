import { InputOutLabal } from '@/ui';
import { Stack } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { Error } from './ModalPassword';

type ModalCurrrentPassword = PropsWithChildren & {
  onValue: (field: string) => (value: string) => void;
  enteredValue: string;
  error: Error | null;
};
export const ModalCurrentPassword: FC<ModalCurrrentPassword> = ({
  children,
  onValue,
  enteredValue,
  error,
}) => {
  return (
    <Stack gap={3}>
      <InputOutLabal
        label="Введите текущий пароль"
        bg=""
        error={!!error?.['old_password']}
        helperText={error?.['old_password']?.[0]}
        value={enteredValue}
        onChange={(event) => onValue('old_password')(event.target.value)}
      ></InputOutLabal>
      {children}
    </Stack>
  );
};
