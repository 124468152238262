import { InputOutLabal } from '@/ui';
import { Stack } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { Error } from './ModalPassword';

type ModalChangePassword = PropsWithChildren & {
  onValue: (field: string) => (value: string) => void;
  enteredValue: { [key in 'new' | 'new_confirm']: string };
  error: Error | null;
};
export const ModalChangePassword: FC<ModalChangePassword> = ({
  children,
  onValue,
  enteredValue,
  error,
}) => {
  return (
    <Stack gap={3}>
      <InputOutLabal
        label="Введите новый пароль"
        bg=""
        error={!!error?.['new_password']}
        helperText={error?.['new_password']?.join(' ')}
        value={enteredValue.new}
        onChange={(event) => onValue('new_password')(event.target.value)}
        sx={{ height: 'auto' }}
      ></InputOutLabal>
      <InputOutLabal
        label="Повторите  ввод нового пароля"
        bg=""
        error={!!error?.['new_password_confirm']}
        helperText={error?.['new_password_confirm']?.join(' ')}
        value={enteredValue.new_confirm}
        onChange={(event) => onValue('new_password_confirm')(event.target.value)}
      ></InputOutLabal>
      {children}
    </Stack>
  );
};
