import { Box, Snackbar, Stack } from '@mui/material';
import { Group } from '../../../../ui/mui/Group/Group';
import { FC, useCallback, useMemo } from 'react';
import { FormConfirmModals, Req, ReqForm } from '../../../../interfaces/req';
import { ViewingField } from '../../../../ui/Fields/ViewingField';
import { SelectForm } from '../../../../ui/mui/Select/Select';
import { useDisclosure } from '../../../../hooks/useDisclosure';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DownloadIcon from '@mui/icons-material/Download';
import { getDateFormat } from '../../../../utils/getDateFormat';
import { SaveCancel } from '../../../buttons/SaveCancel';
import { ModalMui } from '../../../Modal';
import { RescheduleDate } from '../../reschedule-date/RescheduleDate';
import { useReqEditForm } from '../../../../hooks/Reqs/useReqEditForm';
import { useDrivers } from '../../../../hooks/Reqs/useDrivers';
import { RouteState } from './RouteState';
import { ClientSection } from './sections/ClientSection';
import { PaymentSection } from './sections/PaymentSection';
import { ServiceSection } from './sections/ServiceSection';
import { Title } from '../../../../ui/mui/Title/Title';
import { EditConfirmModals } from '../../../modals/modal-confirm/EditConfirmModals';
import {
  DATE_FORMAT_OPTIONS,
  GARBAGE_COLLECTION,
  SNACKBAR_CONFIG,
} from '../../../../constants/constants';
import { useConfirmModals } from '../../../../hooks/Modals/useConfirmModals';
import { ImageLink } from '@/ui';
import { exportImageFetchByReq } from '@/utils';
import { useNavigate } from 'react-router-dom';
import { downloadFile } from '@/utils/downloadFile';
import { MODAL_EDIT_CONFIGS } from '@/params/modal';
import { BadgeRouteStatus } from '@/ui/Badges/RouteStatus';

type EditReqFormProps = {
  req: Req;
};

export const EditReqForm: FC<EditReqFormProps> = ({ req }) => {
  const {
    client,
    source,
    dispatcher1,
    kind,
    driver,
    additional_services,
    req_date,
    status,
    take_container,
    container,
    company_object,
    images,
    unloading_point,
  } = req;
  const navigate = useNavigate();
  const isReqFinished = status === 'Завершена' || status === 'Отменена';
  const [modalDateOpened, modalDateActions] = useDisclosure(false);
  const { modalStates, toggleModal } = useConfirmModals();
  const [snackbarOpened, snackbarActions] = useDisclosure(false);

  const { setFormData, handleSubmit, snackbarMessage, setSnackBarMessage } = useReqEditForm(
    req,
    snackbarActions.open
  );

  const { driverList, vehicleInfo, handleChangeDriver } = useDrivers(driver, setFormData);

  const modalConfirmConfig: FormConfirmModals = useMemo(
    () => ({
      configs: { date: MODAL_EDIT_CONFIGS.date },
      states: { date: modalStates.date },
      handlers: {
        date: {
          onConfirm: () => {
            toggleModal('date');
            modalDateActions.open();
          },
          onClose: () => toggleModal('date'),
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalStates.date, modalDateActions.open, toggleModal]
  );

  const modalDateConfig = {
    opened: modalDateOpened,
    onClose: modalDateActions.close,
    title: 'Изменения даты рейса',
  };

  const handleFormDataChange = useCallback(
    (field: string) => (value: any) => {
      setFormData((prev) => ({ ...(prev ?? {}), [field]: value }) as ReqForm);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleClickDate = () => {
    if (status !== 'Завершена' && status !== 'Отменена') {
      toggleModal('date');
    }
  };

  const handleDownloadImage = async (image_id: number) => {
    try {
      const response = await exportImageFetchByReq(req.id, image_id, navigate);
      if (!response.ok) throw new Error('Файл не найден');

      await downloadFile(response, `image_${image_id}`, 'jpg');
    } catch (error) {
      console.error('Error:', error);
      if (error instanceof Error) {
        setSnackBarMessage(error.message);
        snackbarActions.open();
      }
    }
  };
  const filteredGarbageCollection = useMemo(
    () =>
      kind === 'База - Полигон'
        ? GARBAGE_COLLECTION.filter((item) => item !== 'База')
        : GARBAGE_COLLECTION,
    [kind]
  );

  return (
    <form onSubmit={handleSubmit} id="reqEdit">
      <Snackbar
        open={snackbarOpened}
        autoHideDuration={SNACKBAR_CONFIG.autoHideDuration}
        onClose={snackbarActions.close}
        anchorOrigin={SNACKBAR_CONFIG.anchorOrigin}
        message={snackbarMessage}
      />
      <EditConfirmModals {...modalConfirmConfig} />
      <ModalMui {...modalDateConfig}>
        <RescheduleDate
          closeModal={modalDateActions.close}
          defaultValue={req_date}
          reqId={req.id}
          setErrorMessage={setSnackBarMessage}
          openModal={snackbarActions}
        />
      </ModalMui>
      <Stack gap={3}>
        <ClientSection
          client={client}
          client_object={company_object}
          source={source}
          dispatcher={dispatcher1}
        />
        <Stack gap={2}>
          <Title variant="h4">Маршрут</Title>
          <BadgeRouteStatus>{kind}</BadgeRouteStatus>
          <RouteState
            routeState={kind}
            address_location={company_object?.address}
            isReqFinished={isReqFinished}
            onChange={handleFormDataChange}
            containerState={{ take_container, container }}
            objectContainers={company_object?.containers}
          />
          <SelectForm
            w={400}
            list={filteredGarbageCollection}
            label="Пункт вывоза мусора"
            defaultValue={unloading_point ?? ''}
            onChange={(e) => handleFormDataChange('unloading_point')(e.target.value)}
            disabled={isReqFinished}
          />

          <Group gap={10}>
            <ViewingField
              disabled={isReqFinished}
              label="Дата рейса"
              value={getDateFormat(req_date, DATE_FORMAT_OPTIONS) ?? ''}
              w={10}
              Icon={<CalendarMonthIcon onClick={handleClickDate} />}
            />
            <SelectForm
              name="driver"
              list={driverList}
              w={190}
              label="Водитель"
              defaultValue={driver?.id || ''}
              disabled={isReqFinished}
              onChange={handleChangeDriver as any}
            />
            <ViewingField label="Автомашина" value={vehicleInfo} w={54} />
          </Group>
        </Stack>
        <PaymentSection onChange={handleFormDataChange} req={req} disabled={isReqFinished} />
        <ServiceSection
          initialState={additional_services}
          isFinishedStatus={Boolean(isReqFinished)}
          onChange={handleFormDataChange}
        />
        <Stack gap={2}>
          <Title variant="h4">Документы</Title>
          <Group gap={20}>
            {images.map((image) => (
              <Box
                key={image.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100px',
                  height: '100px',
                  border: '1px solid var(--input-border-color)',
                  borderRadius: '10px',
                  position: 'relative',
                  boxShadow: '4px 3px 2px #F6F6F9',
                }}
              >
                <ImageLink href={image.image} w={70} h={70} radius={20} />

                <DownloadIcon
                  sx={{ position: 'absolute', bottom: 0, right: 0, cursor: 'pointer' }}
                  onClick={() => handleDownloadImage(image.id)}
                />
              </Box>
            ))}
          </Group>
        </Stack>
        <SaveCancel formId="reqEdit" />
      </Stack>
    </form>
  );
};
