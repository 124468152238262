import { Group } from '@/ui';
import { Button } from '@mui/material';
import { FC } from 'react';

type ButtonsProps = {
  onContinue: () => void;
  onCancel: () => void;
  stage?: number;
};
export const Buttons: FC<ButtonsProps> = ({ onContinue, onCancel, stage }) => {
  return (
    <Group align="center" justify="center" gap={20}>
      <Button onClick={onContinue} variant="contained">
        Продолжить
      </Button>
      {stage === 3 ? null : (
        <Button onClick={onCancel} variant="outlined">
          {stage === 2 ? 'Назад' : 'Отменить'}
        </Button>
      )}
    </Group>
  );
};
