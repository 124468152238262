import { CacheKey, CacheState, Counts, Req } from '../../interfaces/req';
import { exportFileFatch, myFetch } from '../../utils';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { TextField, InputAdornment, Box, CircularProgress } from '@mui/material';
import './ReqList.css';
import { NavLink, redirect, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import ModalTableFields from '../../components/ModalTableFields';
import { Select } from '../../components/select/Select';
import { downloadFile } from '../../utils/downloadFile';
import { Pagination } from '@/components';
import { getReqs } from '@/utils/services';
import SETTINGS from '@/settings';
import {
  REQ_DATE_FILTER_ITEMS,
  REQ_PAYMENT_KIND,
  REQ_STATUS_FILTER_ITEMS,
  REQ_TABLE_FIELDS,
} from '@/params';
import { DATE_FORMAT_OPTIONS, STATUS_CLASSES } from '@/constants';
import { useLocalStorageState } from '@/hooks';
import { getDateFormat } from '@/utils/getDateFormat';
import { useDebounce } from '@/hooks/useDebounce';
import { generateCacheKey } from '@/utils/generateCacheKey';
import { cleanupCache } from '@/utils/cleanUpCache';
import { logout } from '@/utils/logout';

export default function ReqList() {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [cache, setCache] = useState<CacheState>({});
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const { me }: { me: any } = useOutletContext();

  const [reqList, setReqList]: any[] = useState([]);
  const [counts, setCounts] = useState<Counts | null>(null);
  const [dateKind, setDateKind] = useLocalStorageState<string>('reqListDateKind', 'today');

  const [start_date, setStartDate] = useLocalStorageState<Date>('reqListStartDate', new Date());
  const [end_date, setEndDate] = useLocalStorageState<Date>('reqListEndDate', new Date());
  const [query, setQuery] = useState('');
  const [status, setStatus] = useState('ALL');
  const [payment_status, setPaymentStatus] = useState('ALL');

  const [page, setPage] = useState<number>(0);
  const [page_size, setPageSize] = useState(10);
  const [count, setCount] = useState(0);

  const debouncedQuery = useDebounce(query);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      return;
    }

    const fetchData = async () => {
      const cacheKey = generateCacheKey<CacheKey>({
        page,
        filters: {
          status,
          payment_status,
          start_date: dayjs(start_date).format('YYYY-MM-DD'),
          end_date: dayjs(end_date).format('YYYY-MM-DD'),
          query: debouncedQuery,
          page_size,
        },
      });

      if (cache[cacheKey]) {
        const cachedData = cache[cacheKey];
        setReqList(cachedData.results);
        setCounts(cachedData.counts);
        setCount(cachedData.counts?.count);
        return;
      }

      setLoading(true);
      try {
        const params = {
          navigate,
          start_date: dayjs(start_date).format('YYYY-MM-DD'),
          end_date: dayjs(end_date).format('YYYY-MM-DD'),
          status,
          payment_status,
          page: String(page + 1),
          page_size: String(page_size),
          query: debouncedQuery,
        };

        const result = await getReqs(params);
        const { results, ...counts } = result;

        setCache((prev) =>
          cleanupCache<CacheState>({
            ...prev,
            [cacheKey]: { results, counts },
          })
        );
        setReqList(results);
        setCounts(counts);
        setCount(counts.count);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    debouncedQuery,
    start_date,
    end_date,
    status,
    page,
    page_size,
    payment_status,
    isFirstLoad,
    cache,
    navigate,
  ]);

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
  };

  const handleClickClearQuery = (): void => {
    setQuery('');
  };
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = (): void => setIsModalOpen(true);
  const closeModal = (): void => setIsModalOpen(false);
  const reqTableFieldsString = localStorage.getItem('reqTableFields');
  let reqTableFieldsObj;
  if (reqTableFieldsString) {
    reqTableFieldsObj = JSON.parse(reqTableFieldsString);
  } else {
    reqTableFieldsObj = {};
  }
  const [tableFieldIsClosed, setTableFieldIsClosed]: [any, (state: any) => void] =
    useState(reqTableFieldsObj);

  const handleExportFile = async () => {
    const newQuery = query.replace(/\s/g, '+');
    const params = {
      start_date: dayjs(start_date).format('YYYY-MM-DD'),
      end_date: dayjs(end_date).format('YYYY-MM-DD'),
      query: newQuery,
      status,
      payment_status,
    };
    const path = location.pathname.replace(/\/+/g, '');
    const response = await exportFileFatch(path, navigate, params);
    try {
      await downloadFile(response, path, 'xlsx');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className="applications-page">
        {loading && (
          <Box
            sx={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              position: 'absolute',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1000,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <ModalTableFields
          state={tableFieldIsClosed}
          setState={setTableFieldIsClosed}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          tableFields={REQ_TABLE_FIELDS}
        />
        <div className="page-top d-flex items-center">
          <div className="page-top__left d-flex items-center">
            <span className="page-top__title">Заявки · {counts?.count}</span>
            <span
              className="page-top__left-settings icon-setting transition"
              onClick={openModal}
            ></span>
          </div>
          <div className="page-top__right d-flex items-center">
            <div className="page-top__filter d-flex">
              {REQ_DATE_FILTER_ITEMS.map(
                (item: {
                  value: string;
                  label: string;
                  dayStart: number;
                  dayEnd: number;
                  withoutDate: boolean;
                }) => {
                  const classList = [
                    'page-top__filter-item',
                    'd-flex items-center',
                    'transition',
                    dateKind === item.value ? 'is-active' : '',
                  ].join(' ');
                  return (
                    <div
                      key={item.value}
                      className={classList}
                      onClick={() => {
                        setDateKind(item.value);
                        if (item.withoutDate) {
                          setStartDate(new Date(0));
                          setEndDate(new Date(new Date().getTime()));
                        } else {
                          const sd = new Date(
                            new Date().getTime() - item.dayEnd * 24 * 60 * 60 * 1000
                          );
                          const ed = new Date(
                            new Date().getTime() - item.dayStart * 24 * 60 * 60 * 1000
                          );
                          setStartDate(sd);
                          setEndDate(ed);
                        }
                      }}
                    >
                      {item.label}
                    </div>
                  );
                }
              )}
            </div>

            <div className="page-top__calendar d-flex items-center">
              <div className="page-top__calendar-icon circle-center">
                <span className="icon-calendar"></span>
              </div>
              <>
                <DatePicker
                  selected={start_date}
                  onChange={(date) => {
                    setDateKind('by_date');
                    setStartDate(date!);
                  }}
                  selectsStart
                  startDate={start_date!}
                  endDate={end_date!}
                  dateFormat="dd.MM.YYYY"
                  shouldCloseOnSelect
                />
                &nbsp;-&nbsp;
                <DatePicker
                  selected={end_date}
                  onChange={(date) => {
                    setEndDate(date!);
                    setDateKind('by_date');
                  }}
                  selectsEnd
                  startDate={start_date!}
                  endDate={end_date!}
                  dateFormat="dd.MM.YYYY"
                  shouldCloseOnSelect
                />
              </>
            </div>

            <div className="page-top__search">
              <TextField
                size="small"
                variant="outlined"
                value={query}
                onChange={handleChangeQuery}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="icon-search"></span>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ display: 'none' }}
                      onClick={handleClickClearQuery}
                    >
                      <span className="icon-close"></span>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="page-top__dispatcher d-flex items-center">
              <div className="page-top__dispatcher-icon circle-center">
                <span className="icon-settings-b"></span>
              </div>

              <span className="page-top__dispatcher-text d-flex items-center">
                Диспетчер · <span className="transition">{me?.full_name}</span>{' '}
                <span className="icon-angle-down transition"></span>
              </span>

              <div className="dispatcher-menu">
                <div className="dispatcher-menu__inner">
                  <a href="/lk" className="dispatcher-menu__item transition">
                    Личные данные
                  </a>
                  <span
                    className="dispatcher-menu__item transition"
                    onClick={() => logout(navigate)}
                  >
                    Выйти
                  </span>
                </div>
              </div>
            </div>

            <NavLink to={'/reqs/add/'} className="page-top__button d-flex items-center transition">
              Новая заявка
            </NavLink>
          </div>
        </div>

        <div className="page-content">
          <div className="applications-page__top d-flex items-center justify-between pr-10">
            <div className="applications-page__filter d-flex">
              {REQ_STATUS_FILTER_ITEMS.map((item, i) => {
                const classList =
                  'applications-page__filter-item d-flex items-center transition' +
                  (item.value === status ? ' is-active' : '');
                return (
                  <div
                    key={i}
                    className={classList}
                    onClick={() => {
                      setStatus(item.value);
                      setPaymentStatus('ALL');
                      setPage(0);
                    }}
                  >{`${item.label} · ${counts?.[item.aggKey] ?? ''}`}</div>
                );
              })}
              {REQ_PAYMENT_KIND.map((item) => {
                return (
                  <div
                    key={item.aggKey}
                    className={
                      'applications-page__filter-item d-flex items-center transition' +
                      (item.value === payment_status ? ' is-active' : '')
                    }
                    onClick={() => {
                      setPaymentStatus(item.value);
                      setStatus('ALL');
                      setPage(0);
                    }}
                  >
                    {`${item.label} · ${counts?.[item.aggKey] ?? ''}`}
                  </div>
                );
              })}
            </div>
            <Box style={{ width: '101px' }}>
              <Select
                onChange={handleExportFile}
                itemList={['Экспорт в xlsx']}
                value={''}
                label={'Действия'}
              />
            </Box>
          </div>

          <div className="applications-page__table">
            <table className="reqs-table table">
              <thead className="table__top">
                <tr className="table__top-tr">
                  <th className="table__top-th th-id">
                    <div className="d-flex items-center">
                      <label className="table__checkbox">
                        <input type="checkbox" className="table__checkbox-input" />
                        <div className="table__checkbox-block d-flex block-center transition">
                          <span className="icon-check"></span>
                        </div>
                      </label>
                      id
                    </div>
                  </th>
                  {!tableFieldIsClosed.status && (
                    <th className="table__top-th th-status">Статус</th>
                  )}
                  {!tableFieldIsClosed.paid && <th className="table__top-th th-paid">Оплата</th>}
                  {!tableFieldIsClosed.client && (
                    <th className="table__top-th th-client">Клиент</th>
                  )}
                  <th className="table__top-th th-address">Адрес объекта</th>
                  {!tableFieldIsClosed.tel && (
                    <th className="table__top-th th-container">Телефон</th>
                  )}
                  {!tableFieldIsClosed.container && (
                    <th className="table__top-th th-container">Контейнер</th>
                  )}
                  {!tableFieldIsClosed.car && <th className="table__top-th th-car">Автомашина</th>}
                  {!tableFieldIsClosed.date && (
                    <th className="table__top-th th-date">Дата заявки</th>
                  )}
                  {!tableFieldIsClosed.amount && <th className="table__top-th th-amount">Сумма</th>}
                  {!tableFieldIsClosed.type && (
                    <th className="table__top-th th-type">Тип заявки</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {reqList &&
                  reqList.map((req: Req) => {
                    return (
                      <tr className="table__row" key={req.id}>
                        <td className="table__td td-id">
                          <div className="table__td-inner d-flex items-center">
                            <label className="table__checkbox">
                              <input type="checkbox" className="table__checkbox-input" />
                              <div className="table__checkbox-block d-flex block-center transition">
                                <span className="icon-check"></span>
                              </div>
                            </label>
                            {req.id}
                          </div>
                        </td>

                        {!tableFieldIsClosed.status && (
                          <td className="table__td td-status">
                            <div className="table__td-inner d-flex items-center">
                              <div
                                className={`td-status__block ${
                                  STATUS_CLASSES[req.status] || 'new'
                                } d-flex items-center`}
                              >
                                {/* classes for statuses: new - Новая, appointed - Назначен, completed - Завершен, in-work - В работе */}
                                {req.status}
                              </div>
                            </div>
                          </td>
                        )}
                        {!tableFieldIsClosed.paid && (
                          <td className="table__td td-paid">
                            <div className="table__td-inner d-flex items-center">
                              <div
                                className={`td-paid__block ${
                                  req.payment_status === 'Оплачен' ? 'paid' : 'not-paid'
                                } d-flex items-center`}
                              >
                                {/* classes for payment: paid - Оплачен, not-paid - Неоплачен */}
                                {req.payment_status}
                              </div>
                            </div>
                          </td>
                        )}
                        {!tableFieldIsClosed.client && (
                          <td className="table__td td-client">
                            <div className="table__td-inner d-flex items-center">
                              {req.client?.name}
                            </div>
                          </td>
                        )}
                        <td className="table__td td-address">
                          <div className="table__td-inner d-flex items-center">
                            {req.address?.location}
                          </div>
                        </td>
                        {!tableFieldIsClosed.tel && (
                          <td className="table__td td-tel">
                            <div className="table__td-inner d-flex items-center">
                              {req.company_object?.contact_persons[0].phone}
                            </div>
                          </td>
                        )}
                        {!tableFieldIsClosed.container && (
                          <td className="table__td td-container">
                            <div className="table__td-inner d-flex items-center">
                              {req.container?.num}
                            </div>
                          </td>
                        )}
                        {!tableFieldIsClosed.car && (
                          <td className="table__td td-car">
                            <div className="table__td-inner d-flex flex-column">
                              <span className="td-car__number">
                                {req.driver?.current_vehicle?.license_plate}
                              </span>
                              <span className="td-car__owner">
                                {req.driver?.last_name} {req.driver?.first_name}
                              </span>
                            </div>
                          </td>
                        )}
                        {!tableFieldIsClosed.date && (
                          <td className="table__td td-date">
                            <div className="table__td-inner d-flex items-center">
                              {getDateFormat(req.req_date, DATE_FORMAT_OPTIONS)}
                            </div>
                          </td>
                        )}
                        {!tableFieldIsClosed.amount && (
                          <td className="table__td td-amount">
                            <div className="table__td-inner d-flex items-center">
                              {req.total_sum.toLocaleString()} &#8381;
                            </div>
                          </td>
                        )}
                        {!tableFieldIsClosed.type && (
                          <td className="table__td td-type">
                            <div className="table__td-inner d-flex items-center">{req.kind}</div>
                          </td>
                        )}
                        <a
                          href={`/reqs/${req.id}/edit/`}
                          className="table__edit circle-center transition"
                        >
                          <span className="icon-edit"></span>
                        </a>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <Pagination
            state={{ count, page, page_size }}
            action={{
              onChangePage: handleChangePage,
              onChangePageSize: handleChangePageSize,
            }}
          />
        </div>
      </div>
    </>
  );
}

export async function getReqResult({
  navigate,
  startDate,
  endDate,
  query,
  currentStatus,
  paymentStatus,
  page = 0,
  pageSize = 0,
}: {
  navigate: (path: string) => void;
  startDate: Date;
  endDate: Date;
  query: string;
  currentStatus: string;
  paymentStatus: string;
  page?: number;
  pageSize?: number;
}) {
  const start_date = dayjs(startDate).format('YYYY-MM-DD');
  const end_date = dayjs(endDate).format('YYYY-MM-DD');
  const newQuery = query.replace(/\s/g, '+');

  const res = await myFetch(
    `${SETTINGS.SITE_URL}/api/v1/reqs/?start_date=${start_date}&end_date=${end_date}&query=${newQuery}&status=${currentStatus}&payment_status=${paymentStatus}&page_size=${pageSize}&page=${page + 1}`,
    {},
    navigate
  );
  if (!res) {
    redirect('/login');
  }

  const reqResult: {
    results: Req[];
    count: number;
    new_count: number;
    finished_count: number;
    paid_count: number;
    not_paid_count: number;
    driver_appointed_count: number;
    in_work_count: number;
  } = await res.json();

  return reqResult;
}
