const companyAddFields = [
  {
    header: 'О компании',
    fields: [
      {
        mykey: 'kind',
        fieldKind: 'select',
        nameField: 'name',
        label: 'Тип компании',
        options: [
          {
            name: 'Клиент',
          },
          {
            name: 'Полигон',
          },
          {
            name: 'Исполнители',
          },
        ],
      },
      {
        mykey: 'org_form',
        fieldKind: 'select',
        nameField: 'name',
        label: 'Орг форма',
        options: [
          {
            name: 'ЮЛ',
          },
          {
            name: 'ФЛ',
          },
        ],
      },
      {
        mykey: 'inn',
        fieldKind: 'text',
        label: 'ИНН',
      },
      {
        mykey: 'name',
        fieldKind: 'text',
        label: 'Наименование',
      },
    ],
  },
  {
    header: '',
    fields: [
      {
        mykey: 'jur_address',
        fieldKind: 'text',
        label: 'Юридический адрес',
      },
      {
        mykey: 'register_date',
        fieldKind: 'date',
        label: 'Дата регистрации',
      },
    ],
  },
  {
    header: '',
    fields: [
      {
        mykey: 'director',
        fieldKind: 'text',
        label: 'Директор',
      },
      {
        mykey: 'phone',
        fieldKind: 'text',
        label: 'Телефон',
      },
      {
        mykey: 'email',
        fieldKind: 'text',
        label: 'Почта',
      },
    ],
  },
  {
    fields: [
      {
        mykey: 'ogrn',
        fieldKind: 'text',
        label: 'ОГРН',
      },
      {
        mykey: 'kpp',
        fieldKind: 'text',
        label: 'КПП',
      },
    ],
    header: '',
  },
];
export default companyAddFields;
