interface ConfirmLabel {
  title: string;
  confirmLabel: string;
}

interface ModalConfirm {
  date?: ConfirmLabel;
  refuse?: ConfirmLabel;
  refuse_confirm?: ConfirmLabel;
  idleFlight?: ConfirmLabel;
  idleFlight_confirm?: ConfirmLabel;
  overloading?: ConfirmLabel;
  overloading_confirm?: ConfirmLabel;
  createReq_confirm?: ConfirmLabel;
  driverfire_confirm?: ConfirmLabel;
}

export const MODAL_EDIT_CONFIGS: ModalConfirm = {
  date: {
    title: 'Подтвердите изменение даты рейса',
    confirmLabel: 'Изменить дату',
  },
  refuse: {
    title: 'Клиент отказался от заявки',
    confirmLabel: 'Отказ от заявки',
  },
  refuse_confirm: {
    title: 'Вы уверены что хотите отменить заявку?',
    confirmLabel: 'Отказать заявку',
  },
  createReq_confirm: {
    title: 'Вы уверены что хотите удалить заявку и все заполненные данные?',
    confirmLabel: 'Сохранить заявку',
  },
  driverfire_confirm: {
    title: 'Вы уверены что хотите уволить водителя',
    confirmLabel: 'Уволить водителя',
  },
};
