import { Button, Stack } from '@mui/material';
import { Title } from '../../../../../ui/mui/Title/Title';
import { Group } from '../../../../../ui/mui/Group/Group';
import { SelectOutLabel } from '../../../../../ui/mui/Select/SelectOutLabel';
import { useEffect, useMemo, useState } from 'react';
import { myFetch } from '../../../../../utils';
import SETTINGS from '../../../../../settings';
import { useNavigate } from 'react-router-dom';
import { Company, ContactPersonObject } from '../../../../../interfaces/company';
import { InputOutLabal } from '../../../../../ui/mui/Input/InputOutLabal';
import { SOURCE_REQ, STATIC_CLIENT_DATA } from '../../../../../constants/constants';
import { useAddFormContext } from '../../../../../hooks/Context/useAddFormContext';

const initialContactPerson: Partial<ContactPersonObject>[] = [
  { first_name: '', email: '', phone: '' },
];

export const ClientSection = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState<Company[] | null>(null);
  const [selectedClient, setSelectedClient] = useState<string | null>(null);
  const [clientData, setClientData] = useState<Company | null>(null);
  const [contactPerson, setContactPerson] =
    useState<Partial<ContactPersonObject>[]>(initialContactPerson);

  const { formData, updateFormData } = useAddFormContext();

  useEffect(() => {
    const fetchClients = async () => {
      const response = await myFetch(`${SETTINGS.SITE_URL}/api/v1/companies/`, {}, navigate);
      const jsonData = await response.json();
      const companies: Company[] = jsonData.results;

      setClients(companies);
    };
    fetchClients();
  }, [navigate]);

  useEffect(() => {
    const selectedClientObject = clientData?.company_objects.filter(
      (obj) => obj.address === formData?.address_location
    );
    if (selectedClientObject && selectedClientObject.length > 0) {
      setContactPerson(selectedClientObject[0]?.contact_persons);
    } else {
      setContactPerson(initialContactPerson);
    }
  }, [formData?.address_location, clientData]);

  useEffect(() => {
    const selected_client = clients?.filter((client) => client.name === selectedClient);

    setClientData(selected_client?.[0] ?? null);
  }, [selectedClient, clients]);

  useEffect(() => {
    if (clientData) {
      updateFormData('client')(clientData.id);
    } else {
      updateFormData('client')(null);
    }
  }, [clientData, updateFormData]);

  const selectClientList = useMemo(
    () =>
      clients
        ? clients.map((client) => ({
            id: client.id,
            label: client.name,
          }))
        : [],
    [clients]
  );

  const clientInfo = STATIC_CLIENT_DATA.map((el) => (
    <InputOutLabal
      key={el.id}
      label={el.label}
      disabled
      value={contactPerson[0][el.value]}
      w={250}
    />
  ));

  return (
    <Stack gap={3}>
      <Title variant="h4">Данные о клиенте</Title>
      <Group gap={24}>
        <SelectOutLabel
          label={'Выберите клиента'}
          list={selectClientList}
          w={300}
          placeholder="Введите наименование компании"
          onChangeValue={(value) => setSelectedClient(value)}
        />
        {clientInfo}
      </Group>
      <Button
        href="/companies/add/"
        target="_blank"
        rel="noopener noreferrer"
        variant="text"
        sx={{
          width: '190px',
          textTransform: 'capitalize',
          textDecoration: 'underline',
          fontSize: '14px',
          fontWeight: 700,
          color: '#000',
          justifyContent: 'flex-start',
        }}
      >
        Добавить клиента
      </Button>
      {formData?.kind === 'База - Полигон' ? null : (
        <SelectOutLabel
          label={'Источник заявки'}
          list={SOURCE_REQ}
          w={250}
          placeholder="Источник заявки"
          onChangeValue={(value) => updateFormData('source')(value)}
        />
      )}
    </Stack>
  );
};
