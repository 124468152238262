import { Title } from '@/ui';
import { Stack } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export const ModalSuccess: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack gap={3}>
      <Title>Пароль успешно изменен</Title>
      {children}
    </Stack>
  );
};
