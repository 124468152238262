import { ModalMui } from '@/components/Modal';
import { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { Buttons } from './Buttons';
import { ModalCostValue } from './ModalCostValue';

type ModalReqCosts = {
  stage: string;
  modalOpened: boolean;
  onClose: () => void;
  callback: (modal: string, value: string) => Promise<void>;
};
export const ModalReqCosts: FC<ModalReqCosts> = ({ stage, modalOpened, onClose, callback }) => {
  const [value, setValue] = useState('');
  const getButtons = (continueAction = () => {}, cancelAction = () => onClose()) => (
    <Buttons onAction={continueAction} onCancel={cancelAction} stage={stage} />
  );

  const getModalContent = () => {
    switch (stage) {
      case 'idleFlight':
        return {
          content: (
            <ModalCostValue label="Укажите сумму за холостой рейс" onValue={setValue}>
              {getButtons(() => callback(stage, value))}
            </ModalCostValue>
          ),
          title: 'Подтвердите оформление "Холостого рейса"? Заявка будет отменена',
        };

      case 'overloading':
        return {
          content: (
            <ModalCostValue label="Сумма клиенту за перегруз" onValue={setValue}>
              {getButtons(() => callback(stage, value))}
            </ModalCostValue>
          ),
          title: 'Укажите сумму за перегруз Сумма будет добавлена к стоимости заявки',
        };

      case 'roof':
        return {
          content: (
            <ModalCostValue label="Укажите сумму заявки с учетом кровли в КГМ" onValue={setValue}>
              {getButtons(() => callback(stage, value))}
            </ModalCostValue>
          ),
          title: 'Подтвердите наличии кровли в КГМ Сумма заявки будет изменена',
        };

      case 'contract':
        return {
          content: (
            <ModalCostValue
              label="Укажите сумму заявки с учетом нарушений договора"
              onValue={setValue}
            >
              {getButtons(() => callback(stage, value))}
            </ModalCostValue>
          ),
          title: 'Подтвердите нарушение условий договора Сумма заявки будет изменена',
        };

      default:
        return {
          content: null,
          title: null,
        };
    }
  };

  const { title, content } = getModalContent();
  return (
    <ModalMui opened={modalOpened} onClose={onClose} title={title ?? ''} w={420}>
      <Stack sx={{ justifyContent: 'center', alignItems: 'center', padding: '20px' }} gap={2}>
        {content}
      </Stack>
    </ModalMui>
  );
};
