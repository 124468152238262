import { useCallback, useState } from 'react';
import { Additional_services } from '../../interfaces/req';
import { getRandomFloat } from '@/utils/getRandomFloat';

export const useServices = (initialServices?: Additional_services[] | []) => {
  const [services, setServices] = useState(initialServices ?? []);

  const addService = useCallback(() => {
    const service: Additional_services = {
      id: getRandomFloat(1.5, 5.5),
      service: null,
      created_at: '',
      updated_at: '',
    };
    setServices([...services, service]);
  }, [services]);

  const removeService = useCallback(
    (id: number) => {
      const mod = services.filter((service) => service.id !== id);
      setServices(mod);
    },
    [services]
  );

  return { services, addService, removeService };
};
