import { Group } from '@/ui';
import { Button } from '@mui/material';
import { FC } from 'react';

type ButtonsProps = {
  onAction: () => void;
  onCancel: () => void;
  stage?: string;
};
export const Buttons: FC<ButtonsProps> = ({ onAction, onCancel, stage }) => {
  return (
    <Group align="center" justify="center" gap={20}>
      <Button onClick={onAction} variant="contained">
        {stage === 'idleFlight' ? 'Отменить заявку' : 'Изменить цену заявки'}
      </Button>
      <Button onClick={onCancel} variant="outlined">
        Отменить
      </Button>
    </Group>
  );
};
